import React, { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import firebase from 'gatsby-plugin-firebase';
import { Input, Label, TextArea } from '../components/FormElements';
import { ButtonBack } from 'pure-react-carousel';
import Select, { Options, OptionsOrGroups } from 'react-select';
import { useMemo } from 'react';
import { nanoid } from 'nanoid';
import emailjs from '@emailjs/browser';

enum CourseType {
  ADULTS = 'E',
  CHILDREN = 'JK',
}

enum Weekday {
  MONDAY = 'MO',
  TUESDAY = 'DI',
  WEDNESDAY = 'MI',
  THURSDAY = 'DO',
  FRIDAY = 'FR',
  SATURDAY = 'SA',
  SUNDAY = 'SO',
}

const getWeekdayLabel = (weekday: Weekday) => {
  switch (weekday) {
    case Weekday.MONDAY:
      return 'Montag';
    case Weekday.TUESDAY:
      return 'Dienstag';
    case Weekday.WEDNESDAY:
      return 'Mittwoch';
    case Weekday.THURSDAY:
      return 'Donnerstag';
    case Weekday.FRIDAY:
      return 'Freitag';
    case Weekday.SATURDAY:
      return 'Samstag';
    case Weekday.SUNDAY:
      return 'Sonntag';
  }
};

enum CourseLocation {
  WINTERTHUR = 'V',
  OBERWINTERTHUR = 'R',
  WIESENDANGEN = 'W',
}

const getCourseLocationLabel = (courseLocation: CourseLocation) => {
  switch (courseLocation) {
    case CourseLocation.WINTERTHUR:
      return 'Winterthur';
    case CourseLocation.OBERWINTERTHUR:
      return 'Oberwinterthur';
    case CourseLocation.WIESENDANGEN:
      return 'Wiesendangen';
  }
};

interface ICourse {
  id: string;
  name: string;
  type: CourseType;
  location: CourseLocation;
  weekDay: Weekday;
  hasKulturLegi: boolean;
}

const STEP_AMOUNT = 3;

const STEPS = ['Kurse', 'Personalien', 'Bemerkungen', 'Anmeldungen'];

const PRIMARY_COLOR = 'rgba(163,28,68,1)';
const SECONDARY_COLOR = 'rgba(255,245,245,1)';

type Option<T> = {
  label: string;
  value: T;
};

const CourseSelect: React.FC<{ course: ICourse | null; courses: ICourse[]; onSelectCourse: (course: ICourse) => void }> = ({
  course,
  courses,
  onSelectCourse,
}) => {
  const [selectedWeekday, setSelectedWeekday] = useState<Option<Weekday> | null>(
    course ? { value: course.weekDay, label: getWeekdayLabel(course.weekDay) } : null
  );
  const [selectedLocation, setSelectedLocation] = useState<Option<CourseLocation> | null>(
    course ? { value: course.location, label: getCourseLocationLabel(course.location) } : null
  );
  const [selectedCourse, setSelectedCourse] = useState<Option<ICourse> | null>(
    course ? { value: course, label: course.name } : null
  );

  const weekdays = useMemo(
    () =>
      [...new Set(courses.map(course => course.weekDay))].sort(
        (a, b) => Object.values(Weekday).indexOf(a) - Object.values(Weekday).indexOf(b)
      ),
    [courses]
  );

  const locations = useMemo(
    () => [...new Set(courses.filter(course => course.weekDay === selectedWeekday?.value).map(course => course.location))],
    [courses, weekdays, selectedWeekday]
  );

  const selectableCourses = useMemo(
    () => [
      ...new Set(
        courses
          .filter(course => course.weekDay === selectedWeekday?.value && course.location === selectedLocation?.value)
          .sort((a, b) => a.name.localeCompare(b.name))
      ),
    ],
    [courses, weekdays, selectedWeekday, selectedLocation]
  );

  return (
    <div style={{ display: 'grid', rowGap: '10px' }}>
      <Select
        value={selectedWeekday}
        onChange={e => {
          setSelectedWeekday(e);
          setSelectedLocation(null);
          setSelectedCourse(null);
        }}
        placeholder="Kurstag"
        options={weekdays.map(day => ({ label: getWeekdayLabel(day), value: day }))}
      />
      {selectedWeekday && (
        <Select
          noOptionsMessage={() => 'Keine Kurse gefunden'}
          value={selectedLocation}
          onChange={e => {
            setSelectedLocation(e);
            setSelectedCourse(null);
          }}
          placeholder="Kursort"
          options={locations.map(location => ({
            label: getCourseLocationLabel(location),
            value: location,
          }))}
        />
      )}
      {selectedWeekday && selectedLocation && (
        <Select
          noOptionsMessage={() => 'Keine Kurse gefunden'}
          value={selectedCourse}
          onChange={e => {
            setSelectedCourse(e);
            onSelectCourse(e.value);
          }}
          placeholder="Kursname"
          options={selectableCourses.map(course => ({
            label: course.name,
            value: course,
          }))}
        />
      )}
    </div>
  );
};

const RequiredStar: React.FC = () => <span className="text-primary -mt-4 text-lg">*</span>;

const AnmeldungenPage: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [selectedCourses, setSelectedCourses] = useState<Array<{ value: ICourse | null; id: string }>>([
    { id: nanoid(), value: null },
  ]);
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthday] = useState('');
  const [parentFirstname, setParentFirstname] = useState('');
  const [parentSurname, setParentsurname] = useState('');
  const [comments, setComments] = useState('');
  const [isDisclaimerAccepted, setIsDisclaimerAccepted] = useState(false);
  const [state, setState] = useState<'initial' | 'submitted' | 'error'>('initial');
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    firebase
      .database()
      .ref('courses/courses')
      .once('value')
      .then(snap => {
        setCourses(snap.val()?.courses ?? []);
      });
  }, []);

  const decrementStep = () => {
    if (activeStep > 0) {
      setActiveStep(step => step - 1);
    }
  };

  const incrementStep = async () => {
    if (
      activeStep === 0 &&
      !selectedCourses.some(({ value }) => value?.weekDay != null && value?.location != null && value?.name != null)
    ) {
      setIsInvalid(true);
    } else if (activeStep === 1) {
      // don't allow to procceed if the form is invalid
      const isFormValid = formRef?.current.reportValidity();
      if (isFormValid) {
        setActiveStep(step => step + 1);
      }
    } else if (activeStep === 3 && isDisclaimerAccepted) {
      try {
        await firebase
          .database()
          .ref(`registrations/${nanoid()}`)
          .set({
            firstname,
            surname,
            zipCode,
            city,
            address,
            email,
            phone,
            birthDate,
            parentFirstname,
            parentSurname,
            comments,
            courseIds: selectedCourses.filter(course => course.value != null).map(course => course.value.id),
            registrationDate: new Date().toISOString(),
            isArchived: false,
          });
        setState('submitted');
        await emailjs
          .send(
            'service_mlac3eo',
            'website',
            {
              toEmail: email,
              firstName: firstname,
              lastName: surname,
              birthDay: birthDate,
              address: `${address} - ${zipCode} ${city}`,
              email: email,
              phone: phone ?? '',
              parent: parentFirstname && parentSurname ? `${parentFirstname} ${parentSurname}` : '-',
              courses:
                !selectedCourses || selectedCourses.length === 0
                  ? ''
                  : selectedCourses.length === 1
                  ? `den Kurs ${selectedCourses[0].value?.name} am ${getWeekdayLabel(
                      selectedCourses[0].value?.weekDay ?? Weekday.MONDAY
                    )} in ${getCourseLocationLabel(selectedCourses[0].value?.location ?? CourseLocation.OBERWINTERTHUR)}`
                  : `die Kurse: ${selectedCourses
                      .map(
                        course =>
                          `${course.value?.name} am ${getWeekdayLabel(
                            course.value?.weekDay ?? Weekday.MONDAY
                          )} in ${getCourseLocationLabel(course.value?.location ?? CourseLocation.OBERWINTERTHUR)}`
                      )
                      .join(', ')}`,
              comments: comments,
            },
            'M63jS5o6WUTPT8vw4'
          )
          .catch(err => console.log(err));
      } catch {
        setState('error');
      }
    } else {
      setActiveStep(step => step + 1);
      setIsInvalid(false);
    }
  };

  const handleCourseSelect = (selectedCourse: ICourse, courseId: string) => {
    setSelectedCourses(
      selectedCourses.map(course => (course.id === courseId ? { id: courseId, value: selectedCourse } : course))
    );
  };

  const handleCourseRemoval = (courseId: string) => {
    console.log(courseId, selectedCourses);
    setSelectedCourses(selectedCourses.filter(course => course.id !== courseId));
  };

  return (
    <Layout location={Routes.ANMELDUNGEN}>
      <SEO title="Kontakt" />
      <div className="pt-8 flex justify-center flex-col items-center p-4" style={{ maxWidth: '100vw', isolation: 'isolate' }}>
        <Title className="mb-4 text-center">Anmeldung</Title>
        {state === 'submitted' ? (
          <div>
            <div className="text-2xl mt-8 flex items-center flex-col">
              <svg
                className="mt-8 w-48 h-48"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64"
                enable-background="new 0 0 64 64"
              >
                <path
                  d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50
	l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
                  fill="#43a047"
                />
              </svg>
              <div className="mt-12">
                <span className="font-bold">Vielen Dank!</span> <br />
                <span className="text-gray-700">Ihre Anmeldung ist bei uns eingegangen.</span>
                <Link
                  to={Routes.HOME}
                  className="block bg-primary text-white px-4 py-2 w-full mt-8 uppercase text-xl text-center"
                  onClick={() => setSelectedCourses(c => [...c, { id: nanoid(), value: null }])}
                >
                  Zurück
                </Link>
              </div>
            </div>
          </div>
        ) : state === 'error' ? (
          <div>
            <div className="text-2xl mt-8 flex items-center flex-col">
              <svg className="mt-8 w-48 h-48" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <title>error</title>
                <path fill="#d33" d="M13.728 1H6.272L1 6.272v7.456L6.272 19h7.456L19 13.728V6.272zM11 15H9v-2h2zm0-4H9V5h2z" />
              </svg>
              <div className="mt-12">
                <span className="font-bold">Da ist leider etwas schiefgelaufen</span> <br />
                <span className="text-gray-700">
                  Versuchen Sie die Anmeldung erneut oder melden Sie sich über das Kontaktformular direkt bei uns
                </span>
                <Link
                  to={Routes.KONTAKT}
                  className="block bg-primary text-white px-4 py-2 w-full mt-8 uppercase text-xl text-center"
                  onClick={() => setSelectedCourses(c => [...c, { id: nanoid(), value: null }])}
                >
                  Kontakt
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            {activeStep === 0 && (
              <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                <p className="font-bold">Hinweis</p>
                <p>
                  Für Schnupperlektionen benutzen Sie <br /> bitte das&nbsp;
                  <Link className="underline" to={Routes.KONTAKT}>
                    Kontakformular
                  </Link>
                </p>
              </div>
            )}
            <div
              className="grid grid-cols-4 relative gap-4 mt-4"
              style={{ width: 'max-content', marginLeft: 'auto', marginRight: 'auto' }}
            >
              {STEPS.map((step, i) => (
                <div className="text-xs grid justify-items-center relative z-10" key={step}>
                  <div
                    className={`bg-red-100 border-primary border text-primar rounded-full w-8 h-8 flex items-center p-3 text-xs ${
                      activeStep >= i ? 'bg-primary text-white' : ''
                    }`}
                  >
                    {i + 1}
                  </div>
                  <div>{step}</div>
                </div>
              ))}
              <div
                className="absolute h-2 w-full"
                style={{
                  top: '21%',
                  left: '40px',
                  border: '1px solid #a31c44',
                  borderImageSlice: '1',
                  borderImageSource:
                    'linear-gradient(90deg, rgba(163,28,68,1) 0%, rgba(163,28,68,1) 77%, rgba(255,255,255,0) 77.01%)',
                  borderLeft: 'none',
                  borderRight: 'none',
                  background: `linear-gradient(90deg, ${activeStep > 0 ? PRIMARY_COLOR : SECONDARY_COLOR} 0%, ${
                    activeStep > 0 ? PRIMARY_COLOR : SECONDARY_COLOR
                  } 25%, ${activeStep > 1 ? PRIMARY_COLOR : SECONDARY_COLOR} 25.01%,  ${
                    activeStep > 1 ? PRIMARY_COLOR : SECONDARY_COLOR
                  } 50%, ${activeStep > 2 ? PRIMARY_COLOR : SECONDARY_COLOR} 50.01%, ${
                    activeStep > 2 ? PRIMARY_COLOR : SECONDARY_COLOR
                  } 76%, rgba(255,255,255,0) 76.01%)`,
                }}
              />
            </div>
            <div className="mt-4">
              {activeStep === 0 && (
                <div className="flex flex-col mt-10">
                  <div className="text-sm mb-4 text-gray-600">
                    Wählen Sie zuerst den Kurstag, <br />
                    den Kursort und dann den Kurs aus. <br /> <br />
                    Sollte ein Kurs nicht aufgelistet sein, <br /> können Sie den Kurs unter "Bemerkungen" angeben.
                  </div>
                  {selectedCourses.map((course, i) => (
                    <div className="mb-12 flex items-start" key={course.id}>
                      <div className="flex-1 mr-4">
                        <CourseSelect
                          course={course.value}
                          courses={courses}
                          onSelectCourse={(selectedCourse: ICourse) => handleCourseSelect(selectedCourse, course.id)}
                        />
                      </div>
                      <button
                        onClick={() => handleCourseRemoval(course.id)}
                        className="p-2 text-primary grid place-content-center text-2xl -mt-2"
                        style={{
                          opacity: i > 0 ? 1 : 0,
                          pointerEvents: i > 0 ? 'all' : 'none',
                          cursor: i > 0 ? 'pointer' : 'initial',
                        }}
                      >
                        x
                      </button>
                    </div>
                  ))}
                  <button
                    className="text-primary ml-auto"
                    onClick={() => setSelectedCourses(c => [...c, { id: nanoid(), value: null }])}
                  >
                    Weiterer Kurs hinzufügen
                  </button>
                </div>
              )}
              {activeStep === 1 && (
                <form ref={formRef} id="signup-form" className="px-4 md:px-0">
                  <div className="md:grid md:grid-cols-2 md:gap-6 mt-10">
                    <div className="">
                      <Label htmlFor="first-name">
                        Vorname <RequiredStar />
                        <Input
                          value={firstname}
                          onChange={e => setFirstname(e.target.value)}
                          required
                          name="firstname"
                          id="first-name"
                          type="text"
                        />
                      </Label>
                    </div>
                    <div className="">
                      <Label htmlFor="last-name">
                        Nachname <RequiredStar />
                        <Input
                          value={surname}
                          onChange={e => setSurname(e.target.value)}
                          required
                          name="lastname"
                          id="last-name"
                          type="text"
                        />
                      </Label>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-6 mt-2">
                    <div className="">
                      <Label htmlFor="zip-code">
                        PLZ <RequiredStar />
                        <Input
                          value={zipCode}
                          onChange={e => setZipCode(e.target.value)}
                          required
                          name="zip-code"
                          id="zip-code"
                          type="text"
                        />
                      </Label>
                    </div>
                    <div className="">
                      <Label htmlFor="city-field">
                        Ort <RequiredStar />
                        <Input
                          value={city}
                          onChange={e => setCity(e.target.value)}
                          required
                          name="city"
                          id="city-field"
                          type="text"
                        />
                      </Label>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-6 mt-2">
                    <div className="">
                      <Label htmlFor="address">
                        Strasse / Nr <RequiredStar />
                        <Input
                          value={address}
                          onChange={e => setAddress(e.target.value)}
                          required
                          name="address"
                          id="address"
                          type="text"
                        />
                      </Label>
                    </div>
                    <div className="">
                      <Label htmlFor="birth-date">
                        Geburtsdatum <RequiredStar />
                        <Input
                          value={birthDate}
                          onChange={e => setBirthday(e.target.value)}
                          required
                          name="birth-date"
                          id="birth-date"
                          type="date"
                        />
                      </Label>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-6 mt-2">
                    <div className="">
                      <Label htmlFor="email-address">
                        Email <RequiredStar />
                        <Input
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                          name="email"
                          id="email-address"
                          type="email"
                        />
                      </Label>
                    </div>
                    <div className="">
                      <Label htmlFor="tel-number">
                        Telefon <RequiredStar />
                        <Input
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          required
                          name="telephone"
                          id="tel-number"
                          type="tel"
                        />
                      </Label>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-6 mt-2">
                    <div className="">
                      <Label htmlFor="parent-firstname">
                        Vorname Erziehungsberechtigte/r
                        <Input
                          value={parentFirstname}
                          onChange={e => setParentFirstname(e.target.value)}
                          name="parent-firstname"
                          id="parent-firstname"
                          type="text"
                        />
                      </Label>
                    </div>
                    <div className="">
                      <Label htmlFor="parent-lastname">
                        Name Erziehungsberechtigte/r
                        <Input
                          value={parentSurname}
                          onChange={e => setParentsurname(e.target.value)}
                          name="parent-lastname"
                          id="parent-lastname"
                          type="text"
                        />
                      </Label>
                    </div>
                  </div>
                </form>
              )}
              {activeStep === 2 && (
                <div className="mt-12">
                  <Label htmlFor="comment">
                    Bemerkungen (Freiwillig)
                    <TextArea
                      value={comments}
                      onChange={e => setComments(e.target.value)}
                      name="comment"
                      id="comment"
                      type="text"
                    />
                  </Label>
                </div>
              )}
              {activeStep === 3 && (
                <div className="mt-12 px-4 md:px-0">
                  <h3 className="text-2xl font-bold">Zusammenfassung</h3>
                  <div className="flex mt-4 flex-wrap">
                    <h4 className="mr-4 font-bold text-xl">Kurse:</h4>
                    {selectedCourses
                      .filter(course => course.value != null)
                      .map(course => (
                        <span className="inline-block mr-2 bg-primary text-white px-3 py-2 rounded-full text-xs">
                          {course.value?.name}
                        </span>
                      ))}
                  </div>

                  <div className="mt-8">
                    <h4 className="mr-4 font-bold text-xl">Personalien:</h4>
                    <div className="mt-2 grid col-gap-4 row-gap-1" style={{ gridTemplateColumns: 'auto 2fr' }}>
                      <span className="font-semibold">Vorname:</span> <span>{firstname}</span>
                      <span className="font-semibold">Nachname:</span> <span>{surname}</span>
                      <span className="font-semibold">Geburtsdatum:</span> <span>{new Date(birthDate).toLocaleDateString()}</span>
                      <span className="font-semibold">Adresse:</span>
                      <span>
                        {address}, {zipCode} {city}
                      </span>
                      <span className="font-semibold">Email:</span> <span>{email}</span>
                      <span className="font-semibold">Telefon:</span> <span>{phone}</span>
                      {(parentFirstname || parentSurname) && (
                        <>
                          <span className="font-semibold">
                            Erziehungs-
                            <br />
                            berechtige/r:
                          </span>
                          <span>
                            {parentFirstname} {parentSurname}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex mt-16">
                    <input
                      checked={isDisclaimerAccepted}
                      onChange={e => setIsDisclaimerAccepted(e.target.checked)}
                      type="checkbox"
                      id="disclaimer"
                      name="disclaimer"
                    />
                    <label htmlFor="disclaimer" className="ml-4 -mt-1 max-w-xl">
                      Ich habe die{' '}
                      <a className="underline text-primary" target="_blank" href="AGB's aha! Studio für Tanz und Bewegung.pdf">
                        AGB's
                      </a>{' '}
                      durchgelesen und bestätige hiermit mein Einverständnis mit den Bestimmungen von “Aha! Studio für Tanz und
                      Bewegung.*
                    </label>
                  </div>
                </div>
              )}
              {isInvalid && (
                <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mt-4 -mb-6" role="alert">
                  <p className="font-bold">Hinweis</p>
                  <p>Bitte wählen Sie mindestens einen Kurs aus</p>
                </div>
              )}
              {STEPS.map(
                (step, i) =>
                  activeStep === i && (
                    <div className="grid grid-cols-2 gap-4 mt-10" key={step}>
                      {activeStep > 0 && (
                        <button
                          className="block transition-all transition-200 bg-red-100 border-primary border text-primary px-4 py-2 w-full mt-2 uppercase text-xl hover:bg-primary hover:text-white"
                          onClick={decrementStep}
                        >
                          Zurück
                        </button>
                      )}
                      <button
                        type="submit"
                        form="signup-form"
                        style={{
                          gridColumn: '2',
                          background: activeStep === 3 && !isDisclaimerAccepted ? 'gray' : '',
                          cursor: activeStep === 3 && !isDisclaimerAccepted ? 'not-allowed' : 'pointer',
                        }}
                        className="block transition-all transition-200 bg-primary text-white px-4 py-2 w-full mt-2 uppercase text-xl hover:bg-primary-dark"
                        onClick={incrementStep}
                      >
                        {i === STEP_AMOUNT ? 'Abschicken' : 'Weiter'}
                      </button>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default AnmeldungenPage;
